export default class CommentSearchUtil {
	public static appendUndo(searchUndo: string[], searchRedo: string[], search: string) {
		const length = searchUndo.length;

		if (length >= 50) searchUndo.splice(0, 1);
		if (length === 0 || searchUndo[length - 1] !== search) {
			searchUndo.push(search);
			searchRedo = [];
		}
	}

	public static undo(searchUndo: string[], searchRedo: string[]) {
		const item = searchUndo.pop() ?? null;
		const undo = searchUndo.slice(-1)[0] ?? null;
		if (item !== null) {
			if (undo !== null) {
				searchRedo.push(item);
				return undo;
			} else {
				searchUndo.push(item);
			}
		}
		return null;
	}

	public static redo(searchUndo: string[], searchRedo: string[]) {
		const item = searchRedo.pop() ?? null;
		if (item !== null) {
			searchUndo.push(item);
			return item;
		}
		return null;
	}
}