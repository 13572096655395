












































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { LiveSessionInfoProperties } from "./CommonLiveSessionProperties";
import DateTimePickerInputView from "@/components/shared/DateTimePickerInputView.vue";
import CommentSearchUtil from "./CommentSearchUtil";

class LiveSessionCommentSearchProperties {
	visible: boolean = false;
	searchA: string = null;
	searchB: string = null;
	dateFrom: Date = null;
	dateTo: Date = null;
	excludeAccepted: boolean = false;

	searchUndoA: string[] = [""];
	searchRedoA: string[] = [];
	searchUndoB: string[] = [""];
	searchRedoB: string[] = [];

	applied: () => void;

	public build() {
		const searchA = this.searchA ?? "";
		const searchB = this.searchB ?? "";
		
		var search = (searchA !== "" ? `(${searchA})` : "");
		search += (searchB !== "" ? `|& (${searchB})` : "");

		if (search.startsWith("|&")) 
			search = search.substring(2);
		
		return search;
	}
}

export { LiveSessionCommentSearchProperties };

@Component({
	components: {
		DateTimePickerInputView,
	},
	data: () => ({
		searchA: "",
		searchB: "",
		dateFrom: null,
		dateTo: null,
		defaultDate: null,
		excludeAccepted: false,

		searchUndoA: [],
		searchRedoA: [],
		searchUndoB: [],
		searchRedoB: []
	})
})
export default class LiveSessionCommentSearchView extends Vue {
	@Prop({ default: new LiveSessionCommentSearchProperties() })
	private properties: LiveSessionCommentSearchProperties;
    @Prop() private liveSessionInfo: LiveSessionInfoProperties;

	public created() {
		const liveSession = this.liveSessionInfo.liveSession;

		this.$data.searchA = this.properties.searchA ?? "";
		this.$data.searchB = this.properties.searchB ?? "";
		this.$data.dateFrom = this.properties.dateFrom;
		this.$data.dateTo = this.properties.dateTo;
		this.$data.defaultDate = liveSession?.startedDate;
		this.$data.excludeAccepted = this.properties.excludeAccepted;

		this.$data.searchUndoA = [...this.properties.searchUndoA];
		this.$data.searchRedoA = [...this.properties.searchRedoA]
		this.$data.searchUndoB = [...this.properties.searchUndoB];
		this.$data.searchRedoB = [...this.properties.searchRedoB]
	}

	public clearSearchA() {
		this.$data.searchA = "";
	}

	public clearSearchB() {
		this.$data.searchB = "";
	}

	public clearDateFrom() {
		this.$data.dateFrom = null;
	}

	public clearDateTo() {
		this.$data.dateTo = null;
	}

	public searchKeydown(type: string, e: KeyboardEvent) {
		var searchUndo: string[];
		var searchRedo: string[];
		var search: string = null;

		if (type === 'A') {
			searchUndo = this.$data.searchUndoA;
			searchRedo = this.$data.searchRedoA;
		} else {
			searchUndo = this.$data.searchUndoB;
			searchRedo = this.$data.searchRedoB;
		}

		if (e.ctrlKey && e.key === 'z') {
			e.stopPropagation();
			search = CommentSearchUtil.undo(searchUndo, searchRedo);
		} else if (e.ctrlKey && e.key === 'y') {
			e.stopPropagation();
			search = CommentSearchUtil.redo(searchUndo, searchRedo);
		}

		if (search !== null) {
			if (type === 'A') {
				this.$data.searchA = search;
			} else {
				this.$data.searchB = search;
			}
		} 
	}

	public async search() {
		this.properties.searchA = this.$data.searchA;
		this.properties.searchB = this.$data.searchB;
		this.properties.dateFrom = this.$data.dateFrom;
		this.properties.dateTo = this.$data.dateTo;		
		this.properties.excludeAccepted = this.$data.excludeAccepted;

		this.properties.searchUndoA = this.$data.searchUndoA;
		this.properties.searchRedoA = this.$data.searchRedoA;
		this.properties.searchUndoB = this.$data.searchUndoB;
		this.properties.searchRedoB = this.$data.searchRedoB;

		CommentSearchUtil.appendUndo(this.$data.searchUndoA, 
			this.$data.searchRedoA, this.$data.searchA);
		CommentSearchUtil.appendUndo(this.$data.searchUndoB, 
			this.$data.searchRedoB, this.$data.searchB);	
		
        await this.liveSessionInfo.events.fire("load-comments", true, false);
		
		if (this.properties.applied !== null)
			this.properties.applied();

        this.properties.visible = false;
	}

	public close() {
        this.properties.visible = false;
    }
}
