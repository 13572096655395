













































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { LiveSessionInfoProperties } from "../common/CommonLiveSessionProperties";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import { LiveSessionProductSalesDialogProperties } from "./LiveSessionProductSalesDialogView.vue";
import LiveSessionDetailService from "@/services/LiveSessionDetailService";

const LiveSessionDataView = () => import("../common/LiveSessionDataView.vue");
const LiveSessionVideoView = () => import("../common/LiveSessionVideoView.vue");
const LiveSessionProductIntroducingView = () => import("./LiveSessionProductIntroducingView.vue");
const LiveSessionProductSalesView = () => import("./LiveSessionProductSalesView.vue");

@Component({
    components: {
        LiveSessionDataView,
        LiveSessionVideoView,
        LiveSessionProductIntroducingView,
        LiveSessionProductSalesView,
    },
    data: () => ({}),
})
export default class LiveSessionPrimaryView extends Vue {
    @Prop() private liveSessionProductSalesDialog: LiveSessionProductSalesDialogProperties;
    @Prop() private properties: LiveSessionInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private snackbar: SnackbarProperties;
    private liveSessionDetailService = new LiveSessionDetailService();
    private handle: number = 0;
    private timerEnabled: boolean = true;

    public get fullscreenSales() {
        return this.properties.fullscreenSales;
    }

    public get visibleData() {
        return this.properties.visibleData;
    }

    public get visibleVideo() {
        return this.properties.visibleVideo;
    }

    public get visibleProductIntroducing() {
        return this.properties.visibleProductIntroducing;
    }

    public get visibleProductSales() {
        return this.properties.visibleProductSales;
    }

    public get showVideo() {
        if (!this.fullscreenSales && this.visibleVideo) {
            return true;
        }
        return this.popupVideo;
    }

    public get popupVideo() {
        return this.properties.popupVideo;
    }

    public created() {
        window.addEventListener("resize", this.adjustSize);
    }

    public destroyed() {
        window.removeEventListener("resize", this.adjustSize);
    }

    public beforeDestroy() {
        this.timerEnabled = false;
        if (this.handle !== 0) clearTimeout(this.handle);
    }

    public mounted() {
        this.adjustSize();
        this.countOrdered();
    }

    @Watch("properties.liveSession.status")
    public async countOrdered() {
        const liveSession = this.properties.liveSession;
        const product = this.properties.product;
        try {
            if (product && product.introducing) {
                const r = await this.liveSessionDetailService.ordered(product.id);
                this.properties.ordered = r.data ?? 0;
            } else {
                this.properties.ordered = 0;
            }
        } catch (e) {
            //ignored
            console.log(e);
        } finally {
            if (this.timerEnabled && liveSession.status === "Started") {
                if (this.handle !== 0) clearTimeout(this.handle);
                this.handle = setTimeout(this.countOrdered, 500);
            }
        }
    }

    public get titleProductIntroducing() {
        var title = this.$t("text.product-introducing");
        const product = this.properties.product;
        const ordered = this.properties.ordered;
        if (product && product.id) {
            title += ` [${this.$t("text.ordered")}: ${ordered}]`;
        }
        return title;
    }

    @Watch("properties.fullscreenSales")
    public async adjustSize() {
        const container = document.getElementById("primary-container");
        this.properties.primaryWidth = container.clientWidth;

        if (this.properties.fullscreenSales) {
            container.style.height = "";
            container.style.overflowY = "hidden";
            await Vue.nextTick();
            container.style.overflowY = "auto";
        } else {
            const footer = document.getElementById("footer");
            const sender = document.getElementById("comment-sender");
            const control = document.getElementById("comment-page-control");
            const headerHeight = 64;
            const titleHeight = 68;
            const footerHeight = footer.clientHeight;
            var containerHeight = window.innerHeight - headerHeight - titleHeight - footerHeight;
            const minHeight = 300 + sender.clientHeight + control.clientHeight;
            if (containerHeight < minHeight) {
                containerHeight = minHeight;
            }
            container.style.height = containerHeight + "px";
            container.style.overflowY = "";
        }
    }

    public switchVisibleData() {
        Vue.set(this.properties, "visibleData", !this.visibleData);
    }

    public switchVisibleVideo() {
        Vue.set(this.properties, "visibleVideo", !this.visibleVideo);
    }

    public switchVisibleProductIntroducing() {
        Vue.set(this.properties, "visibleProductIntroducing", !this.visibleProductIntroducing);
    }

    public switchVisibleProductSales() {
        Vue.set(this.properties, "visibleProductSales", !this.visibleProductSales);
    }
}
