var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"600","persistent":"","scrollable":""},model:{value:(_vm.properties.visible),callback:function ($$v) {_vm.$set(_vm.properties, "visible", $$v)},expression:"properties.visible"}},[_c('v-card',{ref:"content"},[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(_vm._s(_vm.$t("text.search-comment")))]),_c('v-card-text',{staticClass:"pt-5 pb-0"},[_c('v-layout',{attrs:{"d-flex":""}},[_c('v-text-field',{staticClass:"d-flex",attrs:{"label":_vm.$t('text.search') + ' 1',"dense":""},on:{"keydown":function($event){return _vm.searchKeydown('A', $event)}},model:{value:(_vm.searchA),callback:function ($$v) {_vm.searchA=$$v},expression:"searchA"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flex shrink",attrs:{"icon":""},on:{"click":_vm.clearSearchA}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.clear')))])])],1),_c('v-layout',{attrs:{"d-flex":""}},[_c('v-text-field',{staticClass:"d-flex",attrs:{"label":_vm.$t('text.search') + ' 2',"dense":""},on:{"keydown":function($event){return _vm.searchKeydown('B', $event)}},model:{value:(_vm.searchB),callback:function ($$v) {_vm.searchB=$$v},expression:"searchB"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flex shrink",attrs:{"icon":""},on:{"click":_vm.clearSearchB}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.clear')))])])],1),_c('v-layout',{attrs:{"d-flex":""}},[_c('date-time-picker-input-view',{staticClass:"d-flex",attrs:{"label":_vm.$t('text.date-from'),"default-date":_vm.defaultDate},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":_vm.clearDateFrom}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.clear')))])])],1),_c('v-layout',{attrs:{"d-flex":""}},[_c('date-time-picker-input-view',{staticClass:"d-flex",attrs:{"label":_vm.$t('text.date-to'),"default-date":_vm.defaultDate},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":""},on:{"click":_vm.clearDateTo}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.clear')))])])],1),_c('v-checkbox',{attrs:{"label":_vm.$t('text.exclude-accepted'),"dense":""},model:{value:(_vm.excludeAccepted),callback:function ($$v) {_vm.excludeAccepted=$$v},expression:"excludeAccepted"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.search}},[_vm._v(_vm._s(_vm.$t("text.search")))]),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("text.close")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }