import EventManager from "@/utilities/EventManager";

class LiveSessionInfoProperties {
    primaryWidth: number = 0;
    visibleData: boolean = false;
    visibleVideo: boolean = false;
    visibleProductIntroducing: boolean = false;
    visibleProductSales: boolean = false;
    fullscreenSales: boolean = false;
    fullscreenComments: boolean = false;
    popupVideo: boolean = false;
    liveSession: any = { products: [] };
    product: any = {};
    ordered: number = 0;
	liveSessionDetailHistories: any[] = [];
    dateFormat: string = null;
    timeFormat: string = null;
    dateTimeFormat: string = null;
	lastAccepted: any = null;
    events = new EventManager();
}

export { LiveSessionInfoProperties };