import AbstractService from './AbstractService';
import Axios from 'axios';

export default class AuthenticationService extends AbstractService {
    private controller = "facebook";
 
    public login(redirectUrl: string, verificationCode: string) {
        var q = { redirectUrl, verificationCode };
        const url = this.makeUrl(`${this.controller}/login`);
        return Axios.post<any>(url, q, this.makeConfig());
    }

    public link(redirectUrl: string, verificationCode: string) {
        var q = { redirectUrl, verificationCode };
        const url = this.makeUrl(`${this.controller}/link`);
        return Axios.post<any>(url, q, this.makeConfig());
    }

    public loginUrl(redirectUrl: string, extendedPermissions: boolean) {
        var q = { redirectUrl, extendedPermissions };
        const url = this.makeUrlWithQueries(`${this.controller}/login-url`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public checkToken(extendedTokenRequired: boolean, extendedPermissions: boolean) {
        var q = { extendedTokenRequired, extendedPermissions };
        const url = this.makeUrlWithQueries(`${this.controller}/check-token`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public refreshToken(redirectUrl: string, verificationCode: string) {
        var q = { redirectUrl, verificationCode };
        const url = this.makeUrl(`${this.controller}/refresh-token`);
        return Axios.post<any>(url, q, this.makeConfig());
    }

    public extendToken() {
        const url = this.makeUrl(`${this.controller}/extend-token`);
        return Axios.post<any>(url, null, this.makeConfig());
    }

    public findAccounts() {
        const url = this.makeUrl(`${this.controller}/find-accounts`);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public findLiveVideos(q: any) {
        const url = this.makeUrlWithQueries(`${this.controller}/find-live-videos`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }
 }