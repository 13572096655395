


























































import { Vue, Component, Prop } from "vue-property-decorator";

class LiveSessionEditorControlProperties {
    startEnabled: boolean = true;
    endEnabled: boolean = true;
    deleteEnabled: boolean = true;
}

export { LiveSessionEditorControlProperties };

@Component({})
export default class LiveSessionEditorControlView extends Vue {
    @Prop({ default: new LiveSessionEditorControlProperties() })
    public properties: LiveSessionEditorControlProperties;

    private clicked(button: string) {
        this.$emit("clicked", button);
    }
}
