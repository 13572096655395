
















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { LiveSessionInfoProperties } from "../common/CommonLiveSessionProperties";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import { LiveSessionCommentNameHistoryProperties } from "../common/LiveSessionCommentNameHistoryView.vue";
import FacebookCommentService from "@/services/FacebookCommentService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CustomerGroupService from "@/services/CustomerGroupService";
import DateUtil from "@/utilities/DateUtil";

class LiveSessionCommentInfoProperties {
    facebookCommentId: number = 0;
    visible: boolean = false;
}

export { LiveSessionCommentInfoProperties };

@Component({
    data: () => ({
        customerGroups: [],
        facebookComment: {},
        customerGroupId: null,
        loadingCustomerGroups: false,
        saving: false,
    }),
})
export default class LiveSessionCommentInfoView extends Vue {
    @Prop({ default: new LiveSessionCommentInfoProperties() })
    private properties: LiveSessionCommentInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private liveSessionInfo: LiveSessionInfoProperties;
	@Prop() private liveSessionCommentNameHistory: LiveSessionCommentNameHistoryProperties;
    private facebookCommentService = new FacebookCommentService();
    private customerGroupService = new CustomerGroupService();

    public get commentDate() {
        const facebookComment = this.$data.facebookComment;
        const commentDate = facebookComment.commentDate;
        const dateTimeFormat = this.liveSessionInfo.dateTimeFormat;
        return commentDate ? DateUtil.format(new Date(commentDate), dateTimeFormat) : null;
    }

    public get customer() {
        return this.$data.facebookComment.customer;
    }

    public get customerGroup() {
        return this.customer?.customerGroup;
    }

	public get navigatorUrl() {
		const facebookComment = this.$data.facebookComment;
		const facebookAccountId = facebookComment.facebookAccountId;
		return `/management/customers/navigator?facebookAccountId=${facebookAccountId}`;
	}

	public nameHistory() {
		const facebookComment = this.$data.facebookComment;
		this.liveSessionCommentNameHistory.facebookAccountId = facebookComment.facebookAccountId;
		this.liveSessionCommentNameHistory.visible = true;
	}

    public created() {
        this.loadCustomerGroups();
        this.load();
    }

    public async loadCustomerGroups() {
        this.$data.loadingCustomerGroups = true;

        try {
            const r = await this.customerGroupService.get({ hidden: false });
            this.$data.customerGroups = r.data.customerGroups;
        } catch (e) {
            this.$data.customerGroups = [];
        } finally {
            this.$data.loadingCustomerGroups = false;
        }
    }

    public async load() {
        try {
            const r = await this.facebookCommentService.get({
                id: this.properties.facebookCommentId,
                loadInfos: true,
                loadCustomer: true,
                loadCustomerGroup: true
            });
            if (r.data.facebookComments.length == 0) {
                throw new Error(this.$t("message.record-not-found").toString());
            }

            const facebookComment = r.data.facebookComments[0];
            if (facebookComment.customerGroup) {
                this.$data.customerGroupId = facebookComment.customerGroup.id;
            }
            this.$data.facebookComment = facebookComment;
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } 
    }

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;
        try {
            const facebookComment = this.$data.facebookComment;
            const r = await this.facebookCommentService.put({
                id: facebookComment.id,
                version: facebookComment.version,
                values: {
                    "customerGroupId": this.$data.customerGroupId
                }
            });

            await this.liveSessionInfo.events.fire("load-comments", true, false);
            this.properties.visible = false;
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public close() {
        if (this.$data.saving) {
            return;
        }

        this.properties.visible = false;
    }
}
