









































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import FacebookService from "@/services/FacebookService";
import RouteUtil from "@/utilities/RouteUtil";
import DateUtil from "@/utilities/DateUtil";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";

class LiveSessionVideoDialogProperties {
    visible: boolean = false;
	type: string = "LIVE";
}

export { LiveSessionVideoDialogProperties };

@Component({
    data: () => ({
		tab: null,
		liveVideos: [],
		prevVideos: [],
        loadingLiveVideos: false,
		loadingPrevVideos: false,
		liveVideosLoaded: false,
		prevVideosLoaded: false,
        selectedLiveVideo: null,
		selectedPrevVideo: null,
		excludeLinked: true,
    }),
})
export default class LiveSessionVideoDialogView extends Vue {
    @Prop() private properties: LiveSessionVideoDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private facebookService = new FacebookService();
    private dateTimeFormateFormat: string = null;

    public formatBroadcastStartTime(v: any) {
		const tmp = v.broadcastStartTime;
        return tmp ? DateUtil.format(new Date(tmp), this.dateTimeFormateFormat) : "";
    }

	public tabChanged() {
		if (this.$data.tab === 0) {
			this.loadLiveVideos();
		} else if (this.$data.tab === 1) {
			this.loadPrevVideos();
		}
	}

	public excludeLinkedChanged(e: Event) {
		if (this.$data.loadingLiveVideos || this.$data.loadingPrevVideos) {
			return;
		}

		this.$data.liveVideos = [];
		this.$data.prevVideos = [];
		this.$data.selectedLiveVideo = null;
		this.$data.selectedPrevVideo = null;
		this.$data.liveVideosLoaded = false;
		this.$data.prevVideosLoaded = false;

		if (this.$data.tab === 0) {
			this.loadLiveVideos();
		} else if (this.$data.tab === 1) {
			this.loadPrevVideos();
		}
	}

    public async checkToken() {
        var r = await this.facebookService.checkToken(false, true);
        if (r.data.valid) {
            var d = DateUtil.between(new Date(r.data.expiresAt), new Date(), "d");
            if (d <= 7) {
                await this.facebookService.extendToken();
                return true;
            }
        } else {
            var redirectUrl = RouteUtil.getUrlWithoutPathname(true);
            // var query = this.$route.query;
            // var params = this.$route.params;
            redirectUrl += `authenticating?type=refresh&from=live-session`;
            var r = await this.facebookService.loginUrl(redirectUrl, true);
            window.location.href = r.data;
            return false;
        }
        return true;
    }

	public async loadLiveVideos() {
		if (this.$data.liveVideosLoaded) {
			return;
		}

		this.$data.loadingLiveVideos = true;
        try {
            if (await this.checkToken()) {
				const excludeLinked = this.$data.excludeLinked;

                const r = await this.facebookService.findLiveVideos({
					statuses: ["LIVE"],
					excludeLinked
				});

                for (var i = 0; i < r.data.length; i++) {
                    var d = r.data[i];
                    if (!d.title) d.title = "Untitled";
                    d.link = "https://www.facebook.com";
                    if (d.permalink && !d.permalink.startsWith("/")) {
                        d.link += "/";
                    }
                    d.link += d.permalink;
                }

                this.dateTimeFormateFormat = ResponseUtil.getDateTimeFormat(r);
                this.$data.liveVideos = r.data;
				this.$data.liveVideosLoaded = true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "video-load-error" };
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loadingLiveVideos = false;
		}
	}

    public async loadPrevVideos() {
		if (this.$data.prevVideosLoaded) {
			return;
		}

		this.$data.loadingPrevVideos = true;
        try {
            if (await this.checkToken()) {
				const excludeLinked = this.$data.excludeLinked;

				const dateFrom = DateUtil.calculate('-', new Date(), 7, 'd');
                const r = await this.facebookService.findLiveVideos({
					statuses: ["VOD"],
					broadcastDateFrom: dateFrom.getTime(),
					excludeLinked
				});

                for (var i = 0; i < r.data.length; i++) {
                    var d = r.data[i];
                    if (!d.title) d.title = "Untitled";
                    d.link = "https://www.facebook.com";
                    if (d.permalink && !d.permalink.startsWith("/")) {
                        d.link += "/";
                    }
                    d.link += d.permalink;
                }

                this.dateTimeFormateFormat = ResponseUtil.getDateTimeFormat(r);
                this.$data.prevVideos = r.data;
				this.$data.prevVideosLoaded = true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.loadingPrevVideos = false;
		}
    }

    public close() {
        this.properties.visible = false;
    }

    public confirmation() {
		var video: any = null;
		var type: string = null;

		if (this.$data.tab === 0) {
			const liveVideos = this.$data.liveVideos;
			const selected = this.$data.selectedLiveVideo;

			if (selected >= liveVideos.length) {
				return;
			}

			video = liveVideos[selected];
			type = 'live-video';
		} else if (this.$data.tab === 1) {
			const prevVideos = this.$data.prevVideos;
			const selected = this.$data.selectedPrevVideo;

			if (selected >= prevVideos.length) {
				return;
			}

			video = prevVideos[selected];
			type = 'prev-video';
		}
		if (video === null) {
			var m = this.$t("message.video-not-selected");
            AlertDialogProperties.info(this.alertDialog, null, m);
            this.alertDialog.visible = true;
			return;
        } 
		const boardcastStartTime = this.formatBroadcastStartTime(video);

		var t = this.$t("title.confirmation");
		var m = this.$t("message.selected-video-confirmation", [video.title, boardcastStartTime]);
		AlertDialogProperties.standard(this.alertDialog, t, m);
		this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
		this.alertDialog.primaryButton = this.$t("text.yes");
		this.alertDialog.tag = {
			command: "link-video",
			video: video,
			type: type
		};
		this.alertDialog.visible = true;
    }
}
