








































import DateUtil from '@/utilities/DateUtil';
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    data: () => ({
        date: null,
        menu: false,
        modal: false
    })
})
export default class DateTimePickerInputView extends Vue {
    @Prop({ default: null })
    private label: string;
    @Prop({ default: null })
    private value: any;
    @Prop({ default: false })
    private disabled: boolean;
    @Prop({ default: null })
    private format: string;
    @Prop({ default: false })
    private hideDetails: boolean;
    @Prop({ default: false })
    private hideIcon: boolean;
    @Prop({ default: true })
    private keepOpen: boolean;
	@Prop({ default: null })
	private defaultDate: Date;

    public get text() {
        if (!this.value) {
            return null;
        }

        if (this.format) {
            return DateUtil.format(new Date(this.value), this.format);
        } else {
            return new Date(this.value).toLocaleString();
        }
    }

    public set text(v: any) { } //ignored

    public get dateValue() {
        if (this.value) {
            var d = new Date(this.value ?? NaN);
            if (isNaN(d.getTime())) {
                return null;
            } else {
                return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
            }
        } else {
            return null;
        }
    }

    public set dateValue(v: string) { 
        if (v) {
            const d = new Date(this.value ?? NaN);
            if (isNaN(d.getTime())) {
                v += ' 00:00:00';
            } else {
                v += ` ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
            }
            this.$emit("input", new Date(v));
        } else {
            this.$emit("input", null);
        }

        if (!this.keepOpen) {
            this.$data.menu = false;
        }
    } 

    public get timeValue() {
        if (this.value) {
            const d = new Date(this.value ?? NaN);
            if (isNaN(d.getTime())) {
                return null;
            } else {
                return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
            }
        } else {
            return null;
        }
    }

    public set timeValue(v: string) {
        if (v) {
			var d = new Date(this.value ?? NaN);
            if (isNaN(d.getTime())) {
				const defaultDate = new Date(this.defaultDate ?? NaN);
				if (!isNaN(defaultDate.getTime())) {
					d = defaultDate;
				} else {
					d = new Date();
				}
            }
            v = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ` + v;
            this.$emit("input", new Date(v));
        } else {
            this.$emit("input", null);
        }

        if (!this.keepOpen) {
            this.$data.menu = false;
        }
    }
}
