










import { Vue, Prop, Component, Watch } from "vue-property-decorator";
import { LiveSessionInfoProperties } from "../common/CommonLiveSessionProperties";
import LiveSessionDetailService from "@/services/LiveSessionDetailService";

@Component({})
export default class LiveSessionLastAcceptedView extends Vue {
    @Prop() private properties: LiveSessionInfoProperties;
    private liveSessionDetailService = new LiveSessionDetailService();
    private handle: number = 0;
    private timerEnabled = true;

    public get lastAccepted() {
        return this.properties.lastAccepted;
    }
	public set lastAccepted(v: any) {
		this.properties.lastAccepted = v;
	}

	public get liveSession() {
		return this.properties.liveSession;
	}
    public get isStarted() {
        return this.liveSession.status === "Started";
    }

	public get info() {		
		if (!this.lastAccepted) return "";

		const printPrice = this.lastAccepted.price.toFixed(2);
		const printWeight = this.lastAccepted.weight.toFixed(2);
		const fullName = this.lastAccepted.facebookAccount.fullName;

		return `${fullName}, ${printPrice}, ${printWeight}`;
	}

	public created() {
		this.properties.events.subscribe('load-last-accepted', this.load);
    }

	public mounted() {
        this.load(true);
	}

    public beforeDestroy() {
        this.timerEnabled = false;
        if (this.handle !== 0) clearTimeout(this.handle);
    }

	public destroyed() {
		this.properties.events.remove('load-last-accepted', this.load);
	}

    @Watch("properties.liveSession.status")
    public async load(reschedule: boolean = true) {
        try {
			const liveSessionId = this.liveSession?.id ?? 0;
			if (liveSessionId === 0) return;

			const r = await this.liveSessionDetailService.get({
				rowStart: 0,
				rowCount: 1,
				liveSessionId: liveSessionId,
				loadFacebookAccount: true,
				loadLiveSessionProduct: true
			});
						
			const details: any[] = r.data.liveSessionDetails;
			this.lastAccepted = details.length > 0 ? details[0] : null;
        } catch (e) {
            //ignored
            console.log(e);
        } finally {
            this.$data.loading = false;

            if (this.timerEnabled && reschedule && this.isStarted) {
                if (this.handle !== 0) clearTimeout(this.handle);
                this.handle = setTimeout(this.load, 2000);
            }
        }
    }
}
