export default class ElementUtil {
	public static getScrollPercent(element: HTMLElement) {
		const scrollPercent = Number(element.scrollTop / (element.scrollHeight - element.clientHeight));
		const tmp = isNaN(scrollPercent) ? 0 : scrollPercent;
		return Math.ceil(tmp * 100) / 100;
	}

	public static getScrollTop(element: Element, percent: number) {
		return (element.scrollHeight - element.clientHeight) * percent;
	}
}