import { render, staticRenderFns } from "./LiveSessionCommentView.vue?vue&type=template&id=4aa2c600&"
import script from "./LiveSessionCommentView.vue?vue&type=script&lang=ts&"
export * from "./LiveSessionCommentView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VDivider,VFlex,VIcon,VLayout,VPagination,VSelect,VSimpleCheckbox,VSpacer,VSpeedDial,VTextField,VTooltip})
