









































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import { LiveSessionCommentNameHistoryProperties } from "./LiveSessionCommentNameHistoryView.vue";
import { LiveSessionCommentSearchProperties } from "./LiveSessionCommentSearchView.vue";
import { LiveSessionInfoProperties } from "./CommonLiveSessionProperties";
import FacebookCommentService from "@/services/FacebookCommentService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import DateUtil from "@/utilities/DateUtil";

class LiveSessionCommentDialogProperties {
    liveSessionId: number = 0;
    facebookCommentId: number = 0;
    visible: boolean = false;
}

export { LiveSessionCommentDialogProperties };

@Component({
    data: () => ({
        facebookComment: {},
        message: "",
        saving: false,
    }),
})
export default class LiveSessionCommentDialogView extends Vue {
    @Prop({ default: new LiveSessionCommentDialogProperties() })
    private properties: LiveSessionCommentDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
	@Prop() private liveSessionCommentNameHistory: LiveSessionCommentNameHistoryProperties;
	@Prop() private liveSessionCommentSearch: LiveSessionCommentSearchProperties;
    @Prop() private liveSessionInfo: LiveSessionInfoProperties;
    private facebookCommentService = new FacebookCommentService();

    public get commentDate() {
        const facebookComment = this.$data.facebookComment;
        const commentDate = facebookComment.commentDate;
        const dateTimeFormat = this.liveSessionInfo.dateTimeFormat;
        return commentDate ? DateUtil.format(new Date(commentDate), dateTimeFormat) : null;
    }

	public get navigatorUrl() {
		const facebookComment = this.$data.facebookComment;
		const facebookAccountId = facebookComment.facebookAccountId;
		return `/management/customers/navigator?facebookAccountId=${facebookAccountId}`;
	}

	public nameHistory() {
		const facebookComment = this.$data.facebookComment;
		this.liveSessionCommentNameHistory.facebookAccountId = facebookComment.facebookAccountId;
		this.liveSessionCommentNameHistory.visible = true;
	}

    public created() {
        this.load();
    }

    public async load() {
        try {
            const r = await this.facebookCommentService.get({
                id: this.properties.facebookCommentId,
                loadInfos: true
            });
            if (r.data.facebookComments.length == 0) {
                throw new Error(this.$t("message.record-not-found").toString());
            }

            const facebookComment = r.data.facebookComments[0];
            this.$data.facebookComment = facebookComment;
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } 
    }

    public async reply() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;
        try {
            const facebookComment = this.$data.facebookComment;
            await this.facebookCommentService.send({
                liveSessionId: this.properties.liveSessionId,
                facebookCommentId: facebookComment.id,
                message: this.$data.message
            });

            await this.liveSessionInfo.events.fire("load-comments", true, false);
            this.properties.visible = false;
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

	public async exclude() {
		const facebookComment = this.$data.facebookComment;
		var message = facebookComment.message ?? "";
		if (message !== "") {
			message = "message:<not>" + message;

			var searchB = this.liveSessionCommentSearch.searchB ?? "";
			if (searchB !== "") 
				searchB += "|&" + message;
			else
				searchB = message;

			this.liveSessionCommentSearch.searchB = searchB;
        	await this.liveSessionInfo.events.fire("load-comments", true, false);
		}
		this.properties.visible = false;
	}

    public close() {
        if (this.$data.saving) {
            return;
        }

        this.properties.visible = false;
    }
}
