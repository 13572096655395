







































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { LiveSessionAcceptOrderDialogProperties } from "./LiveSessionAcceptOrderDialogView.vue";
import { LiveSessionCommentInfoProperties } from "./LiveSessionCommentInfoView.vue";
import AbstractLiveSessionCommentView from "../common/AbstractLiveSessionCommentView.vue";
import SystemSettingService from "@/services/SystemSettingService";
import ExceptionUtil from "@/utilities/ExceptionUtil";

@Component({
    data: () => ({
		speeddial: false,
        prices: [],
    }),
})
export default class LiveSessionCommentView extends AbstractLiveSessionCommentView {
    @Prop() private liveSessionAcceptOrderDialog: LiveSessionAcceptOrderDialogProperties;
    @Prop() private liveSessionCommentInfo: LiveSessionCommentInfoProperties;
    private systemSettingService = new SystemSettingService();

    public commentAccountInfo(comment: any) {
        const vTotalAmount = comment.totalAmount ?? 0;
        const vTotalCurrent = comment.totalCurrent ?? 0;
        const vTotalUnconfirm = comment.totalUnconfirm ?? 0;
        const vTotalConfirmed = comment.totalConfirmed ?? 0;
        const vTotalPaid = comment.totalPaid ?? 0;
        const vTotalCancelled = comment.totalCancelled ?? 0;
        const vBalance = comment.balance ?? 0;

        var totalAmount: string = vTotalAmount.toFixed(2);
        var totalCurrent: string = vTotalCurrent.toFixed(2);
        var totalUnconfirm: string = vTotalUnconfirm.toFixed(2);
        var totalConfirmed: string = vTotalConfirmed.toFixed(2);
        var totalPaid: string = vTotalPaid.toFixed(0);
        var totalCancelled: string = vTotalCancelled.toFixed(0);
        var balance: string = vBalance.toFixed(2);

        totalAmount = totalAmount.padStart(5 + 3).replaceAll(" ", "&nbsp;");
        totalCurrent = totalCurrent.padStart(4 + 3).replaceAll(" ", "&nbsp;");
        totalUnconfirm = totalUnconfirm.padStart(4 + 3).replaceAll(" ", "&nbsp;");
        totalConfirmed = totalConfirmed.padStart(5 + 3).replaceAll(" ", "&nbsp;");
        totalPaid = totalPaid.padStart(4).replaceAll(" ", "&nbsp;");
        totalCancelled = totalCancelled.padStart(3).replaceAll(" ", "&nbsp;");
        balance = balance.padStart(5 + 3).replaceAll(" ", "&nbsp;");

        var cTotalAmount = "black";
        var cTotalCurrent = "black";
        var cTotalUnconfirm = "black";
        var cTotalConfirmed = "black";
        var cTotalPaid = "black";
        var cTotalCancelled = "black";
        var cBalance = "black";

        if (vTotalAmount > 0) cTotalAmount = "#1976d2";
        if (vTotalCurrent > 0) cTotalCurrent = "#1976d2";
        if (vTotalUnconfirm > 0) cTotalUnconfirm = "#1976d2";
        if (vTotalConfirmed > 0) cTotalConfirmed = "#1976d2";
        if (vTotalPaid > 0) cTotalPaid = "#1976d2";

        if (vTotalCancelled === 1) cTotalCancelled = "red";
        else if (vTotalCancelled >= 2) cTotalCancelled = "maroon";

        if (vBalance > 0) cBalance = "#1976d2";

        totalAmount = `<span style='color:${cTotalAmount}'>${totalAmount} TA</span>`;
        totalCurrent = `<span style='color:${cTotalCurrent}'>${totalCurrent} TN</span>`;
        totalUnconfirm = `<span style='color:${cTotalUnconfirm}'>${totalUnconfirm} TU</span>`;
        totalConfirmed = `<span style='color:${cTotalConfirmed}'>${totalConfirmed} TC</span>`;
        totalPaid = `<span style='color:${cTotalPaid}'>${totalPaid} PD</span>`;
        totalCancelled = `<span style='color:${cTotalCancelled}'>${totalCancelled} CL</span>`;
        balance = `<span style='color:${cBalance}'>${balance} BL</span>`;

        return `[${totalAmount}, ${totalCurrent}, ${totalUnconfirm}, ${totalConfirmed}, ${totalPaid}, ${totalCancelled}, ${balance}]`;
    }

    public acceptable(comment: any) {
        const appScopedId = comment?.appScopedId;
        const closed = appScopedId === "-- CLOSED --"
        return comment && comment.facebookAccountId && !comment.page && !closed;
    }

    public created() {
        window.addEventListener("resize", this.adjustHeight);
        this.properties.events.subscribe("accept", this.accept);
        this.properties.events.subscribe("load-comments", this.load);
		this.properties.events.subscribe("alert-dialog-clicked", this.alertDialogClicked);
    }

    public destroyed() {
        window.removeEventListener("resize", this.adjustHeight);
        this.properties.events.remove("accept", this.accept);
        this.properties.events.remove("load-comments", this.load);
		this.properties.events.remove("alert-dialog-clicked", this.alertDialogClicked);
    }

    public async mounted() {
		this.footer = document.getElementById("footer");
        this.sender = this.$el.querySelector("#comment-sender");
        this.container = this.$el.querySelector("#comment-container");
        this.control = this.$el.querySelector("#comment-page-control");

        this.adjustHeight();
        await this.loadSystemSetting();
        await this.load(true);
    }

	public async acceptOne(facebookComment: any, price: number, weight: number) {
		const props = { quantity: 1, price, weight };
		await this.accept(facebookComment, props);
	}

    public acceptMore(comment: any) {
		const product = this.properties.product ?? {};

        this.liveSessionAcceptOrderDialog.comment = comment;
		this.liveSessionAcceptOrderDialog.weight = product.weight ?? 0;
        this.liveSessionAcceptOrderDialog.visible = true;
    }

    public async accept(facebookComment: any, props: any) {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const product = this.properties.product;
			const productId = product?.id ?? 0;
			const price = props.price ?? null;
			const weight = props.weight ?? null;
			const excludeAccepted = this.liveSessionCommentSearch.excludeAccepted;

            if (productId === 0) {
				var t = this.$t("title.product-not-introducing");
                var m: any = this.$t("message.live-session-product-not-introducing");
                AlertDialogProperties.standard(this.alertDialog, t, m);
				this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
				this.alertDialog.primaryButton = this.$t("text.yes");
				this.alertDialog.tag = { command: "product-not-introducing" };
                this.alertDialog.visible = true;
            } else {
                await this.liveSessionDetailService.save({
                    liveSessionProductId: productId,
                    facebookCommentId: facebookComment.id,
					facebookAccountId: null,
                    quantity: props.quantity,
                    price,
					weight,
					remarks: props.remarks,
                    overridePrice: price !== null,
					overrideWeight: weight !== null,
					excludeAccepted
                });

				this.properties.liveSessionDetailHistories.push({ 
                    liveSessionProductId: productId,
					facebookCommentId: facebookComment.id,
					fullName: facebookComment.fullName,
					quantity: props.quantity,
					price: price ?? 0,
					weight: weight ?? product.weight ?? 0
				});

				const printPrice = Number(price ?? 0).toFixed(2);
				const printWeight = Number(weight ?? product.weight ?? 0).toFixed(2);

                SnackbarProperties.reset(this.snackbar);
                this.snackbar.message = this.$t("message.order-accepted", [
					facebookComment.fullName, printPrice, printWeight
				]);
                this.snackbar.alignment = "top-center";
                this.snackbar.timeout = 3000;
                this.snackbar.visible = true;

                if (this.liveSession.status === "Ended") {
                    await this.properties.events.fire("load-product-sales", false);
					await this.properties.events.fire("load-last-accepted", false);
                    await this.load(true, false);
                }

                return true;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
				var code = e.response?.data?.code;
				if (code === "product-not-introducing") {
					var t = this.$t("title.product-not-introducing");
					var m: any = this.$t("message.live-session-product-not-introducing");
					AlertDialogProperties.standard(this.alertDialog, t, m);
					this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
					this.alertDialog.primaryButton = this.$t("text.yes");
					this.alertDialog.tag = { command: "product-not-introducing" };
				} else {
                	var m = ExceptionUtil.getMessage(e);
                	AlertDialogProperties.error(this.alertDialog, null, m);
				}
				this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
        return false;
    }

	public async revertLastOrder(order: any) {
		if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

		try {
			await this.liveSessionDetailService.decrease({
				liveSessionProductId: order.liveSessionProductId,
				facebookCommentId: order.facebookCommentId,
				quantity: order.quantity,
				price: order.price,
				weight: order.weight
			});

			var index = this.histories.indexOf(order);
			if (index !== -1) this.histories.splice(index, 1);

			SnackbarProperties.reset(this.snackbar);
			this.snackbar.message = this.$t("message.order-reverted");
			this.snackbar.alignment = "top-center";
			this.snackbar.timeout = 3000;
			this.snackbar.visible = true;

			if (this.liveSession.status === "Ended") {
				await this.properties.events.fire("load-product-sales", false);
				await this.properties.events.fire("load-last-accepted", false);
				await this.load(true, false);
			}
		} catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
		} finally {
			this.$data.saving = false;
		}
	}

    public async updateMainReaded(comment: any) {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            await this.facebookCommentService.put({
                id: comment.id,
                version: comment.version,
                values: {
                    mainReaded: comment.mainReaded.toString(),
                },
            });

            if (this.liveSession.status !== "Started") {
                await this.load();
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async loadSystemSetting() {
        try {
            const r = await this.systemSettingService.standardProductPrices();
			this.$data.prices = r.data.map((x) => x.price ?? 0);
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public commentInfo(comment: any) {
        if (!comment.facebookAccountId || comment.page) {
            return;
        }

        this.liveSessionCommentInfo.facebookCommentId = comment.id;
        this.liveSessionCommentInfo.visible = true;
    }

    public commentDialog(comment: any) {
        if (!comment.facebookAccountId || comment.page) {
            return;
        }

        this.liveSessionCommentDialog.liveSessionId = this.liveSession.id;
        this.liveSessionCommentDialog.facebookCommentId = comment.id;
        this.liveSessionCommentDialog.visible = true;
    }

	public revertLastOrderDialog() {
		if (this.histories.length === 0) return;

		const order = this.histories[this.histories.length - 1];
		const printPrice = Number(order.price ?? 0).toFixed(2);
		const printWeight = Number(order.weight ?? 0).toFixed(2);

		var t = this.$t("text.revert-last-order");
		var m: any = this.$t("message.revert-last-order-confirmation", [
			order.fullName, printPrice, printWeight
		]);
		AlertDialogProperties.standard(this.alertDialog, t, m);
		this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
		this.alertDialog.primaryButton = this.$t("text.no");
		this.alertDialog.tag = { command: "revert-last-order", order };
		this.alertDialog.visible = true;
	}

    public filterDetailPsid(comment: any) {
        this.properties.events.fire("filter-detail", "psid: " + comment.pageScopedId);
    }

	public async alertDialogClicked(button: string) {
		const tag = this.alertDialog.tag;

		if (tag.command === "product-not-introducing" && button === this.$t("text.yes")) {
			await this.properties.events.fire("open-default");
		} else if (tag.command === "revert-last-order" && button === this.$t("text.yes")) {
			await this.revertLastOrder(tag.order);
		}
	}
}
