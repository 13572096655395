












































































import { Vue, Component, Prop } from "vue-property-decorator";
import { LiveSessionInfoProperties } from "../common/CommonLiveSessionProperties";
import { LiveSessionCommentNameHistoryProperties } from "../common/LiveSessionCommentNameHistoryView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import { Options } from "autonumeric";
import CommonUtil from "@/utilities/CommonUtil";
import AutoNumericUtil from "@/utilities/AutoNumericUtil";

class LiveSessionAcceptOrderDialogProperties {
    comment: any = {};
	price: 0;
	weight: 0;
    visible: boolean = false;
}

export { LiveSessionAcceptOrderDialogProperties };

@Component({
    data: () => ({
        quantity: 1,
        price: 0,
		weight: 0,
		remarks: null
    })
})
export default class LiveSessionAcceptOrderDialogView extends Vue {
    @Prop({ default: new LiveSessionAcceptOrderDialogProperties() }) 
    private properties: LiveSessionAcceptOrderDialogProperties;
    @Prop() private liveSessionInfo: LiveSessionInfoProperties;
	@Prop() private liveSessionCommentNameHistory: LiveSessionCommentNameHistoryProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private snackbar: SnackbarProperties;
    private inputQuantity: HTMLInputElement;
    private inputPrice: HTMLInputElement;
	private inputWeight: HTMLInputElement;

	public get comment() {
		return this.properties.comment;
	}

	public get navigatorUrl() {
		const facebookAccountId = this.comment.facebookAccountId;
		return `/management/customers/navigator?facebookAccountId=${facebookAccountId}`;
	}

	public nameHistory() {
		this.liveSessionCommentNameHistory.facebookAccountId = this.comment.facebookAccountId;
		this.liveSessionCommentNameHistory.visible = true;
	}

    public mounted() {
        const dialog = <Vue>this.$refs.content;
        if (dialog) {
            this.inputQuantity = dialog.$el.querySelector("#quantity");
            this.inputPrice = dialog.$el.querySelector("#price");
			this.inputWeight = dialog.$el.querySelector("#weight");

            this.updateFormatter();
        }

		this.$data.price = this.properties.price ?? 0;
		this.$data.weight = this.properties.weight ?? 0;
    }

    public updateFormatter() {
        setTimeout(() => {
            const options1: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false
            };
            const options2 = CommonUtil.clone(options1);
            options2.decimalPlaces = 0;

            AutoNumericUtil.findOrNew(this.inputQuantity, null, options2)
                .set(this.$data.quantity ?? 0);
            AutoNumericUtil.findOrNew(this.inputPrice, null, options1)
                .set(this.$data.price ?? 0);
			AutoNumericUtil.findOrNew(this.inputWeight, null, options1)
				.set(this.$data.weight ?? 0);
        }, 150);
    }

    public async submit() {
        const comment = this.properties.comment;
		const props = {
			quantity: this.$data.quantity,
			price: this.$data.price ?? 0,
			weight: this.$data.weight ?? 0,
			remarks: this.$data.remarks
		};

        var r = await this.liveSessionInfo.events.fire('accept', comment, props);
        if (r.every(x => x)) this.properties.visible = false;
    }

    public cancel() {
        this.properties.visible = false;
    }
}
