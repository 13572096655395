





































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { LiveSessionInfoProperties } from "../common/CommonLiveSessionProperties";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import { Options } from "node_modules/autonumeric";
import MathService from "@/services/MathService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AutoNumericUtil from "@/utilities/AutoNumericUtil";
import CommonUtil from "@/utilities/CommonUtil";
import FacebookAccountService from "@/services/FacebookAccountService";
import LiveSessionDetailService from "@/services/LiveSessionDetailService";

class LiveSessionProductSalesDialogProperties {
	liveSessionProduct: any = null;
    liveSessionDetailId: number = null;
	isNew: boolean = false;
    visible: boolean = false;
}

export { LiveSessionProductSalesDialogProperties };

@Component({
    data: () => ({
		liveSessionDetail: {},
		pageScopedId: null,
        saving: false,
    }),
})
export default class LiveSessionProductSalesDialogView extends Vue {
    @Prop({ default: new LiveSessionProductSalesDialogProperties() })
    private properties: LiveSessionProductSalesDialogProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    @Prop() private liveSessionInfo: LiveSessionInfoProperties;
    private liveSessionDetailService = new LiveSessionDetailService();
	private facebookAccountService = new FacebookAccountService();
    private mathService = new MathService();
    private inputQuantity: HTMLInputElement;
    private inputPrice: HTMLInputElement;
	private inputWeight: HTMLInputElement;
    private inputTotalWeight: HTMLInputElement;
    private inputTotalAmount: HTMLInputElement;

    private lastValue: any = {};

	public get liveSession() {
		return this.liveSessionInfo.liveSession;
	}

	public get liveSessionProduct() {
		return this.$data.liveSessionDetail.liveSessionProduct ?? {};
	}

    public get facebookComment() {
        return this.$data.liveSessionDetail.facebookComment ?? {};
    }

	public get facebookAccount() {
		return this.$data.liveSessionDetail.facebookAccount ?? {};
	}

	public get visibleComment() {
		const liveSessionDetail = this.$data.liveSessionDetail;
		const facebookCommentId = liveSessionDetail.facebookCommentId ?? 0;
		return !this.properties.isNew && facebookCommentId !== 0;
	}

	public get navigatorUrl() {
		const facebookAccountId = this.$data.liveSessionDetail.facebookAccountId;
		return `/management/customers/navigator?facebookAccountId=${facebookAccountId}`;
	}

    public updateFormatter() {
        setTimeout(() => {
            const options1: Options = {
                minimumValue: "0",
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false,
            };            
            const options2 = CommonUtil.clone(options1);
            options2.decimalPlaces = 0;

			const liveSessionDetail = this.$data.liveSessionDetail;

            AutoNumericUtil.findOrNew(this.inputQuantity, null, options2)
                .set(liveSessionDetail.quantity ?? 0);
            AutoNumericUtil.findOrNew(this.inputPrice, null, options1)
                .set(liveSessionDetail.price ?? 0);
			AutoNumericUtil.findOrNew(this.inputWeight, null, options1)
				.set(liveSessionDetail.weight ?? 0);
            AutoNumericUtil.findOrNew(this.inputTotalWeight, null, options1)
                .set(liveSessionDetail.totalWeight ?? 0);
            AutoNumericUtil.findOrNew(this.inputTotalAmount, null, options1)
                .set(liveSessionDetail.totalAmount ?? 0);
        }, 150);
    }

    public async calculate(e: Event) {
        if (e.type === "keydown" && (<KeyboardEvent>e).key !== "Enter") {
            return;
        }

        try {
			const liveSessionDetail = this.$data.liveSessionDetail;
            const quantity = Number(liveSessionDetail.quantity ?? 0);
            const weight = Number(liveSessionDetail.weight ?? 0);
            const price = Number(liveSessionDetail.price ?? 0);

            if (this.lastValue.quantity !== quantity || 
				this.lastValue.price !== price || 
				this.lastValue.weight !== weight) {
                const totalWeight = await this.mathService.round(quantity * weight, 2);
                const totalAmount = await this.mathService.round(quantity * price, 2);
                AutoNumericUtil.set(this.inputTotalWeight, totalWeight);
                AutoNumericUtil.set(this.inputTotalAmount, totalAmount);
            }

            this.lastValue.quantity = quantity;
            this.lastValue.price = price;
			this.lastValue.weight = weight;
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public mounted() {
        const dialog = <Vue>this.$refs.content;

        if (dialog) {
            this.inputQuantity = dialog.$el.querySelector("#quantity");
            this.inputPrice = dialog.$el.querySelector("#price");
			this.inputWeight = dialog.$el.querySelector("#weight");
            this.inputTotalWeight = dialog.$el.querySelector("#total-weight");
            this.inputTotalAmount = dialog.$el.querySelector("#total-amount");

            this.inputQuantity.addEventListener("blur", this.calculate);
            this.inputPrice.addEventListener("blur", this.calculate);
			this.inputWeight.addEventListener("blur", this.calculate);
            this.inputQuantity.addEventListener("keydown", this.calculate);
            this.inputPrice.addEventListener("keydown", this.calculate);
			this.inputWeight.addEventListener("keydown", this.calculate);

			this.load();
        }
    }

	public async load() {
		try {
			if (this.properties.isNew) {
				const liveSessionProduct = this.properties.liveSessionProduct;
				const weight = liveSessionProduct?.weight ?? 0;

				const liveSessionDetail = {
					liveSessionProduct,
					quantity: 1,
					price: 0,
					totalAmount: 0,
					weight,
					totalWeight: weight
				};

				this.$data.pageScopedId = null;
				this.$data.liveSessionDetail = liveSessionDetail;
			} else {
				const liveSessionDetailId = this.properties.liveSessionDetailId ?? 0;
				const r = await this.liveSessionDetailService.get({
					id: liveSessionDetailId,
					loadLiveSessionProduct: true,
					loadFacebookComment: true,
					loadFacebookAccount: true
				});

				if (r.data.liveSessionDetails.length == 0) {
                	throw new Error(this.$t("message.record-not-found").toString());
				}

				this.$data.liveSessionDetail = r.data.liveSessionDetails[0];
				this.$data.pageScopedId = this.facebookAccount.pageScopedId;
			}
            this.updateFormatter();
		} catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
		}
	}

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
			const liveSessionProduct = this.properties.liveSessionProduct;
			const liveSessionDetail = this.$data.liveSessionDetail;
			if (this.properties.isNew) {
				const r = await this.facebookAccountService.post({
					pageScopedId: this.$data.pageScopedId
				});
				
				await this.liveSessionDetailService.save({ 
					liveSessionProductId: liveSessionProduct.id,
					facebookCommentId: null,
					facebookAccountId: r.data.id,
					quantity: liveSessionDetail.quantity,
					price: liveSessionDetail.price,
					weight: liveSessionDetail.weight,
					remarks: liveSessionDetail.remarks,
					overridePrice: true,
					overrideWeight: true
				});
			} else {
				await this.liveSessionDetailService.replace({ liveSessionDetail });
			}

			if (this.liveSession.status === "Ended") {
            	await this.liveSessionInfo.events.fire("load-product-sales", false);
				await this.liveSessionInfo.events.fire("load-last-accepted", false);
				await this.liveSessionInfo.events.fire("load-comments", true, false);
			}
            this.properties.visible = false;
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public close() {
        if (this.$data.saving) {
            return;
        }

        this.properties.visible = false;
    }
}
