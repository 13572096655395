var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.clicked('create')}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.new-session')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.startEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('start')}}},on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.start-session')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.endEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('stop')}}},on),[_c('v-icon',[_vm._v("mdi-stop")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.end-session')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"disabled":!_vm.properties.deleteEnabled,"large":"","icon":""},on:{"click":function($event){return _vm.clicked('delete')}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('text.delete-record')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }