
import { Prop, Vue, Component, Watch } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import { LiveSessionInfoProperties } from "./CommonLiveSessionProperties";
import { LiveSessionCommentDialogProperties } from "./LiveSessionCommentDialogView.vue";
import { LiveSessionCommentSearchProperties } from "./LiveSessionCommentSearchView.vue";
import FacebookAccountPictureService from "@/services/FacebookAccountPictureService";
import FacebookCommentService from "@/services/FacebookCommentService";
import LiveSessionDetailService from "@/services/LiveSessionDetailService";
import DateUtil from "@/utilities/DateUtil";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ElementUtil from "@/utilities/ElementUtil";
import FilterUtil from "@/utilities/FilterUtil";
import CommentSearchUtil from "./CommentSearchUtil";

@Component({
    data: () => ({
        commentCounts: [50, 100, 200, 500, 1000],
        commentCount: 50,
        pageCount: 1,
        pageNo: 1,
        comment: "",
		searchA: "",
        saving: false,
        sending: false,
        reload: true,
    })
})
export default class LiveSessionCommentView extends Vue {
    @Prop() protected properties: LiveSessionInfoProperties;
	@Prop() protected liveSessionCommentSearch: LiveSessionCommentSearchProperties;
	@Prop() protected liveSessionCommentDialog: LiveSessionCommentDialogProperties;
    @Prop() protected snackbar: SnackbarProperties;
    @Prop() protected alertDialog: AlertDialogProperties;
	protected facebookAccountPictureService = new FacebookAccountPictureService();
    protected facebookCommentService = new FacebookCommentService();
    protected liveSessionDetailService = new LiveSessionDetailService();

    protected handle: number = 0;
    protected timerEnabled: boolean = true;
    protected queueHandle: number = 0;

    protected titleHeight: number = 68;
	protected footer: HTMLElement;
	protected sender: HTMLElement;
	protected container: HTMLElement;
	protected control: HTMLElement;

    protected comments: any[] = [];

    public get commentCountProps() {
        return {
            top: true
        };
    }

	public get liveSession() {
		return this.properties.liveSession;
	}

	public get histories() {
		return this.properties.liveSessionDetailHistories;
	}

    public get fullscreenComments() {
        return this.properties.fullscreenComments;
    }
	public set fullscreenComments(value: boolean) {
		Vue.set(this.properties, 'fullscreenComments', value);
	}

	public get pageTotalVisible() {
		return this.fullscreenComments ? 20 : 10;
	}

	public pictureUrl(comment: any) {
		return this.facebookAccountPictureService.previewUrl({
			facebookAccountId: comment.facebookAccountId
		});
	}

    public commentStyle(comment: any) {
        return {
            "background-color": comment.backgroundColor ?? "#9be7ff",
            "border-left": `8px solid ${comment.tagColor ?? "#2286c3"}`,
        };
    }

    public messageStyle(comment: any) {
        return `color: ${comment.messageColor ?? "#000000"}`;
    }

    public fullNameStyle(comment: any) {
        return `color: ${comment.fullNameColor ?? "#000000"}`;
    }
	
    public commentName(comment: any) {
        var fullName = comment.fullName ?? `-- ${this.$t("text.unnamed")} --`;
		if (comment.uniqueNo) fullName += ` [${comment.uniqueNo}]`;
		return fullName;
    }	

    public commentKeydown(e: KeyboardEvent) {
        if (e.key === "Enter") {
            this.send();
        }
    }

	public commentSearch() {
		const searchUndoA = this.liveSessionCommentSearch.searchUndoA;
		const searchRedoA = this.liveSessionCommentSearch.searchRedoA;
		const searchA = this.$data.searchA;

		this.liveSessionCommentSearch.searchA = searchA;
		CommentSearchUtil.appendUndo(searchUndoA, searchRedoA, searchA);

		this.liveSessionCommentSearch.applied = () => {
			this.$data.searchA = this.liveSessionCommentSearch.searchA;
		};	
		this.liveSessionCommentSearch.visible = true;
	}

	public clearSearch() {
		const searchUndoA = this.liveSessionCommentSearch.searchUndoA;
		const searchRedoA = this.liveSessionCommentSearch.searchRedoA;

		this.$data.searchA = "";
		this.liveSessionCommentSearch.searchA = "";
		CommentSearchUtil.appendUndo(searchUndoA, searchRedoA, "");

		this.load(true, false);
	}

    public beforeDestroy() {
        this.timerEnabled = false;
        if (this.handle !== 0) clearTimeout(this.handle);
    }

    public adjustHeight() {
        const headerHeight = 64;
        const footerHeight = this.footer.clientHeight;
        const subtitleHeight = this.sender.clientHeight;
        const controlHeight = this.control.clientHeight;

        var containerHeight =
            window.innerHeight -
            headerHeight -
            this.titleHeight -
            subtitleHeight -
            controlHeight -
            footerHeight - 1;
        if (containerHeight < 300) containerHeight = 300;
        this.container.style.height = containerHeight + "px";
	}
		
    public formatCommentDate(comment: any) {
        if (!comment.commentDate) return "";

        const v = new Date(comment.commentDate);
        const s = DateUtil.between(new Date(), v, "s");
        
        const secs = [5, 10, 15, 30, 60];
        for (var i = 0; i < secs.length; i++) {
            if (s < secs[i]) return this.$t("message.live-session-comment-date1", [secs[i].toString()]);
        }        

        const mins = [5, 10, 15, 30, 60];
        for (var i = 0; i < secs.length; i++) {
            if (s < mins[i]) return this.$t("message.live-session-comment-date2", [mins[i].toString()]);
        }     
        
        return DateUtil.format(v, this.properties.dateTimeFormat);
    }
	
    public async send() {
        try {
            await this.facebookCommentService.send({
                liveSessionId: this.liveSession.id,
                message: this.$data.comment,
            });
            this.$data.comment = "";
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public loadQueue(delay: number = 750) {
        if (this.queueHandle !== 0) {
            clearTimeout(this.queueHandle);
        }

        this.queueHandle = setTimeout(() => {
			const searchUndoA = this.liveSessionCommentSearch.searchUndoA;
			const searchRedoA = this.liveSessionCommentSearch.searchRedoA;
			const searchA = this.$data.searchA;

			this.liveSessionCommentSearch.searchA = searchA;
			CommentSearchUtil.appendUndo(searchUndoA, searchRedoA, searchA);

            this.queueHandle = 0;
            this.load(true, false);
        }, delay);
    }

    @Watch("properties.liveSession.status")
    public async load(force: boolean = false, reschedule: boolean = true) {
        const pageNo = this.$data.pageNo;
        const rowCount = this.$data.commentCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.loading = true;
        try {
            if (force || this.$data.reload) {
				const scrollPercent = ElementUtil.getScrollPercent(this.container);
				const liveSessionId = this.liveSession?.id ?? 0;
                if (liveSessionId !== 0) {
                    const r = await this.facebookCommentService.get({
                        rowStart: rowStart,
                        rowCount: rowCount,
                        countRecord: true,
                        liveSessionId,
						commentDate: FilterUtil.filterDate(
							false, 
							this.liveSessionCommentSearch.dateFrom, 
							this.liveSessionCommentSearch.dateTo
						),
                        search: this.liveSessionCommentSearch.build(),
                        loadInfos: true,
                        loadColors: true,
						excludeAccepted: this.liveSessionCommentSearch.excludeAccepted
                    });
                    var count = Math.ceil(r.data.count / rowCount);
                    if (count < 1) count = 1;

                    const comments: any[] = r.data.facebookComments;

					comments.forEach(e => {
						e.changed = e.fullNameChanged;
					});

                    this.comments = comments.sort((lhs, rhs) => lhs.id - rhs.id);
                    await Vue.nextTick();
                    this.$data.pageCount = count;
                    if (pageNo > count) {
                        this.$data.pageNo = count;
                    }
					
					this.container.scrollTop = ElementUtil.getScrollTop(this.container, scrollPercent);
                }
            }
        } catch (e) {
            SnackbarProperties.reset(this.snackbar);
            this.snackbar.timeout = 3000;
            this.snackbar.alignment = "bottom-right";
            this.snackbar.message = this.$t("message.load-comment-failed");
            this.snackbar.visible = true;
        } finally {
            if (this.timerEnabled && reschedule && this.liveSession.status === "Started") {
                if (this.handle !== 0) clearTimeout(this.handle);
                this.handle = setTimeout(this.load, 1500);
            }
        }
    }

	public searchKeydown(e: KeyboardEvent) {
		const searchUndoA = this.liveSessionCommentSearch.searchUndoA;
		const searchRedoA = this.liveSessionCommentSearch.searchRedoA;
		var searchA: string = null;

		if (e.ctrlKey && e.key === 'z') {
			e.stopPropagation();
			searchA = CommentSearchUtil.undo(searchUndoA, searchRedoA);
		} else if (e.ctrlKey && e.key === 'y') {
			e.stopPropagation();
			searchA = CommentSearchUtil.redo(searchUndoA, searchRedoA);
		}

		if (searchA !== null) {
			this.liveSessionCommentSearch.searchA = searchA;
			this.$data.searchA = searchA;
			this.load(true, false);
		}
	}
}
