


































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import { LiveSessionEditorControlProperties } from "./LiveSessionEditorControlView.vue";
import { LiveSessionVideoDialogProperties } from "./LiveSessionVideoDialogView.vue";
import { LiveSessionAcceptOrderDialogProperties } from "./LiveSessionAcceptOrderDialogView.vue";
import { LiveSessionProductSalesDialogProperties } from "./LiveSessionProductSalesDialogView.vue";
import { LiveSessionCommentInfoProperties } from "./LiveSessionCommentInfoView.vue";
import { LiveSessionCommentDialogProperties } from "../common/LiveSessionCommentDialogView.vue";
import { LiveSessionCommentSearchProperties } from "../common/LiveSessionCommentSearchView.vue";
import { LiveSessionCommentNameHistoryProperties } from "../common/LiveSessionCommentNameHistoryView.vue";
import { LiveSessionInfoProperties } from "../common/CommonLiveSessionProperties";
import LiveSessionLastAcceptedView from "./LiveSessionLastAcceptedView.vue";
import LiveSessionEditorControlView from "./LiveSessionEditorControlView.vue";
import LiveSessionPrimaryView from "./LiveSessionPrimaryView.vue";
import LiveSessionCommentView from "./LiveSessionCommentView.vue";
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";
import LiveSessionService from "@/services/LiveSessionService";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const SnackbarView = () => import("@/components/shared/SnackbarView.vue");
const LiveSessionVideoDialogView = () => import("./LiveSessionVideoDialogView.vue");
const LiveSessionAcceptOrderDialogView = () => import("./LiveSessionAcceptOrderDialogView.vue");
const LiveSessionProductSalesDialogView = () => import("./LiveSessionProductSalesDialogView.vue");
const LiveSessionCommentInfoView = () => import("./LiveSessionCommentInfoView.vue");
const LiveSessionCommentDialogView = () => import("../common/LiveSessionCommentDialogView.vue");
const LiveSessionCommentSearchView = () => import("../common/LiveSessionCommentSearchView.vue");
const LiveSessionCommentNameHistoryView = () => import("../common/LiveSessionCommentNameHistoryView.vue");

@Component({
    metaInfo: {
        link: [{ rel: "stylesheet", href: "/live-session-info.css" }],
    },
    components: {
		LiveSessionLastAcceptedView,
        LiveSessionEditorControlView,
        AlertDialogView,
        SnackbarView,
        LiveSessionCommentView,
        LiveSessionPrimaryView,
        LiveSessionVideoDialogView,
        LiveSessionAcceptOrderDialogView,
        LiveSessionProductSalesDialogView,
        LiveSessionCommentInfoView,
        LiveSessionCommentDialogView,
		LiveSessionCommentSearchView,
		LiveSessionCommentNameHistoryView
    },
    data: () => ({
        saving: false,
    }),
})
export default class LiveSessionInfoView extends Mixins(InfoViewMixin) {
    private properties = new LiveSessionInfoProperties();
    private alertDialog = new AlertDialogProperties();
    private snackbar = new SnackbarProperties();
    private editorControl = new LiveSessionEditorControlProperties();
    private liveSessionVideoDialog = new LiveSessionVideoDialogProperties();
    private liveSessionAcceptOrderDialog = new LiveSessionAcceptOrderDialogProperties();
    private liveSessionProductSalesDialog = new LiveSessionProductSalesDialogProperties();
    private liveSessionCommentInfo = new LiveSessionCommentInfoProperties();
    private liveSessionCommentDialog = new LiveSessionCommentDialogProperties();
	private liveSessionCommentSearch = new LiveSessionCommentSearchProperties();
	private liveSessionCommentNameHistory = new LiveSessionCommentNameHistoryProperties();
    private liveSessionService = new LiveSessionService();

    public get title() {
        const tmp1 = this.$t("title.live-session");
        const status: string = this.liveSession?.status ?? "";
		if (status === "") return tmp1;
        const tmp2 = this.$t("text." + status.toLowerCase());
        return `${tmp1} - ${tmp2}`;
    }

    public get liveSession() {
        return this.properties.liveSession;
    }

    public get fullscreenSales() {
        return this.properties.fullscreenSales;
    }

    public get fullscreenComments() {
        return this.properties.fullscreenComments;
    }

    public refreshEditorControl() {
        const liveSession = this.liveSession;
        if (liveSession.status === "Preparing") {
            this.editorControl.startEnabled = true;
            this.editorControl.endEnabled = false;
            this.editorControl.deleteEnabled = true;
        } else if (liveSession.status === "Started") {
            this.editorControl.startEnabled = false;
            this.editorControl.endEnabled = true;
            this.editorControl.deleteEnabled = false;
        } else {
            this.editorControl.startEnabled = false;
            this.editorControl.endEnabled = false;
            this.editorControl.deleteEnabled = false;
        }
    }

    public created() {
        this.htmlOverflowY();
    }

    public async mounted() {
        this.mode = this.$route.params.mode;

        if (!this.checkMode() || this.isView()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else if (this.isCreate()) {
            await this.create(); //create new
        } else {
            await this.load();
        }
    }

    public destroyed() {
        document.body.parentElement.style.overflowY = "";
    }

    public async htmlOverflowY() {
        //required for firefox to hide scrollbar properly
        var html = document.body.parentElement;
        html.style.overflowY = "hidden";
        await Vue.nextTick();
        html.style.overflowY = "auto";
    }

    public async load() {
        try {
            var id = this.$route.query.id;
            const r = await this.liveSessionService.get({ id });
            if (r.data.liveSessions.length === 0) {
                throw new Error(this.$t("message.record-not-found").toString());
            }
            const liveSession = r.data.liveSessions[0];

            this.properties.dateFormat = ResponseUtil.getDateFormat(r);
            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.properties.liveSession = liveSession;
            if (liveSession.status === "Preparing") {
                this.properties.visibleData = true;
            }

            this.refreshEditorControl();
            await this.properties.events.fire("loaded");
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async create() {
        if (this.$data.saving) {
            return;
        }

        this.$data.saving = true;
        try {
            const liveSession = this.properties.liveSession;
            const r = await this.liveSessionService.post(liveSession);

            this.properties.dateFormat = ResponseUtil.getDateFormat(r);
            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.properties.liveSession = r.data;

            await this.$router.replace(
                "/management/live-sessions/modify?id=" + this.properties.liveSession.id
            );
            this.mode = "modify";

            this.refreshEditorControl();
            await this.properties.events.fire("loaded");
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async attach(video: any) {
        if (this.$data.saving) {
            return;
        }

        try {
            this.$data.saving = true;

            const liveSession = this.properties.liveSession;
            const r = await this.liveSessionService.attach(liveSession.id, video.id);

            this.properties.dateFormat = ResponseUtil.getDateFormat(r);
            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.properties.liveSession = r.data;

            this.refreshEditorControl();
            await this.properties.events.fire("loaded");
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async start(video: any) {
        if (this.$data.saving) {
            return;
        }

        try {
            this.$data.saving = true;

            const liveSession = this.properties.liveSession;
            const r = await this.liveSessionService.started(liveSession.id, video.id);

            this.properties.dateFormat = ResponseUtil.getDateFormat(r);
            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.properties.liveSession = r.data;

            this.refreshEditorControl();
            await this.properties.events.fire("loaded");
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async remove() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const liveSession = this.properties.liveSession;
            await this.liveSessionService.delete(liveSession);
            AlertDialogProperties.deleted(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "record-deleted" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async stop() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const liveSession = this.properties.liveSession;
            const r = await this.liveSessionService.ended(liveSession.id, "Manually", null);

            this.properties.dateFormat = ResponseUtil.getDateFormat(r);
            this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
            this.properties.liveSession = r.data;

            this.refreshEditorControl();
            await this.properties.events.fire("loaded");
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public async control(button: string) {
        if (button === "create") {
            this.properties.liveSession = {};
            await this.create();
        } else if (button === "start") {
            this.liveSessionVideoDialog.visible = true;
        } else if (button === "stop") {
            this.stopDialog();
        } else if (button === "delete") {
            this.removeDialog();
        }
    }

    public stopDialog() {
        var t = this.$t("title.confirmation");
        var m = this.$t("message.live-session-stop-confirmation");
        AlertDialogProperties.standard(this.alertDialog, t, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "stop" };
        this.alertDialog.visible = true;
    }

    public removeDialog() {
        var m = this.$t("message.live-session-delete-confirmation");
        AlertDialogProperties.delete(this.alertDialog, m);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "remove" };
        this.alertDialog.visible = true;
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag) {
            if (tag.command === "remove" && button === this.$t("text.yes")) {
                await this.remove();
            } else if (tag.command === "stop" && button === this.$t("text.yes")) {
                await this.stop();
            } else if (tag.command === "remove-detail" && button === this.$t("text.yes")) {
                await this.properties.events.fire("remove-detail", tag.record);
            } else if (tag.command === "link-video" && button === this.$t("text.yes")) {
                this.liveSessionVideoDialog.visible = false;
                if (tag.type === "live-video") {
                    await this.start(tag.video);
                } else if (tag.type === "prev-video") {
                    await this.attach(tag.video);
                }
            }
        }
		await this.properties.events.fire("alert-dialog-clicked", button);
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            if (
                tag.command === "invalid-mode" ||
                tag.command === "load-error" ||
                tag.command === "record-deleted"
            ) {
                await this.$router.push("/management/live-sessions");
            }
        }
    }
}
